<template>
  <div class="">
    <lenon-list-page
      :table-loading="tableLoading"
      title="Signatures"
      :show-search="true"
      :show-details="true"
      :columns="columns"
      :rows="signatures"
      :show-profile-photo="true"
    >
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start">
          <lenon-button
            icon="SettingsIcon"
            label="Signatures"
            tool-tip-text="Manage Document Signatures(CRUD)"
            @onClick="showSignatureSetupModal()"
          />
        </div>
      </template>
      <template #document_type="{row}">
        {{ getDocumentType(row.item.document_type) }}
      </template>
      <template #row-details="{row}">
        <b-card>
          <b-row
            class="mb-2"
          >
            <b-col
              md="12"
              class="mb-1"
            >
              <strong>Classes </strong>
              <div class="row px-1">
                <div
                  v-for="(school_class,index) in row.item.classes"
                  :key="index"
                  style="margin-bottom: 5px; margin-right: 3px;"
                >
                  <b-badge variant="light-success">
                    {{ reducedClasses[+school_class] }}
                  </b-badge>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </template>
      <template #actions="{row}">
        <lenon-button
          icon-only
          variant="flat-primary"
          :icon="row.detailsShowing?'EyeOffIcon':'EyeIcon'"
          @onClick="row.toggleDetails"
        />
      </template>
    </lenon-list-page>
    <signature-setup
      :modal-opened="signatureModalOpened"
      @modalClosed="closeModal('signature')"
    />
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import { FETCH_SIGNATURES_Q } from '@/graphql/queries'
import LenonButton from '@/lenon/components/LenonButton.vue'
import SignatureSetup from '@/views/admin/signatures/SignatureSetup.vue'

export default {
  name: 'Signatures',
  components: {
    SignatureSetup,
    LenonButton,
    LenonListPage,
    BBadge,
  },
  mixins: [showToast],
  data() {
    return {
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          label: 'Title',
          key: 'title',
        },
        {
          label: 'Signed By',
          key: 'name',
        },
        {
          label: 'Document Type',
          key: 'document_type',
        },
        {
          label: 'Date',
          key: 'updated_at',
        },
        {
          label: 'Action',
          key: 'actions',
        },
      ],
      tableLoading: false,
      signatureModalOpened: false,
      reducedClasses: {},
    }
  },
  computed: {
    signatures() {
      return this.$store.getters['schoolSignatures/signatures']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
  },
  mounted() {
    this.getSignatures()
    this.reduceCurrentClasses()
  },
  methods: {
    getDocumentType(type) {
      switch (type) {
        case 1:
          return 'Terminal Report'
        case 2:
          return 'Receipt'
      }
    },
    reduceCurrentClasses() {
      this.reducedClasses = this.classes.reduce((cumulatedValue, currentValue) => {
        // eslint-disable-next-line no-param-reassign
        cumulatedValue[+currentValue.id] = currentValue.name
        return cumulatedValue
      }, {})
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'signature':
          this.signatureModalOpened = false
          break
      }
    },
    showSignatureSetupModal() {
      this.signatureModalOpened = true
    },
    getSignatures() {
      this.tableLoading = this.signatures.length < 1
      this.$apollo.query({
        query: FETCH_SIGNATURES_Q,
      })
        .then(res => {
          this.tableLoading = false
          if (res.data && !res.errors) {
            this.$store.commit('schoolSignatures/setSignatures', res.data.signatures)
          }
        })
        .catch(err => {
          this.tableLoading = false
          this.showError('Something went wrong, please try again')
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
