<template>
  <!--    Grading System-->
  <validation-observer
    ref="signatureForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      title="Signature Details"
      :show="signatureModalOpened"
      size="md"
      :hide-footer="true"
      :show-overlay="signatureLoading"
      @onClose="closeSignatureModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedSignatureId"
            placeholder="All Signatures"
            :options="signatures"
            label-name="title"
            value-name="id"
            @input="populateSignatureForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteSignature()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
                  variant="flat-danger"
                  icon="XIcon"
                  label=""
                  tool-tip-text="Clear Form"
                  @onClick="resetSignatureForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <lenon-input
        v-model="signature.title"
        name="name"
        placeholder="Signature Title, eg. Lower Primary Report Signature"
        rules="required"
        label="Title"
      />
      <lenon-input
              v-model="signature.name"
              name="name"
              placeholder="Signer Name, eg. Kofi Addae(Headmaster)"
              rules="required"
              label="Signer Name"
      />
      <lenon-select
        v-model="signature.document_type"
        :options="documentTypes"
        label="Document Type"
        rules="required"
      />
      <div
        class="col-12 mt-1"
        style="width:100%; height: 250px; border: 1px black dotted"
      >
        <VueSignaturePad
          ref="signaturePad"
          :options="{ onBegin, onEnd }"
        />
      </div>
      <div class="mt-1">
        <lenon-button
          label="Clear Signature"
          icon="XIcon"
          variant="outline-danger"
          @onClick="clearSignature"
        />
      </div>
      <div class="mt-2">
        <lenon-multi-select
          v-model="signature.classes"
          name="classes"
          label-name="name"
          value-name="id"
          placeholder="Select Classes"
          label="Affected Classes"
          :options="filteredClasses"
          rules="required"
          @remove="handleRemoveClass"
        />
        <div v-html="missingClassText('signatures')" />
      </div>
      <b-form-checkbox
        v-model="allClasses"
        class="my-1"
      >
        Select All Classes
      </b-form-checkbox>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeSignatureModal()"
          />
          <lenon-button
            :label="updateSignatureMode?'Update':'Create'"
            :disabled="invalid || !signature.signature"
            :loading="signatureLoading"
            loading-text="Loading..."
            @onClick="updateSignatureMode?updateSignature():createSignature()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
// eslint-disable-next-line import/extensions
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'

import {
  CREATE_SIGNATURE_M,
  DELETE_SIGNATURE_M,
  UPDATE_SIGNATURE_M,
} from '@/graphql/mutations'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import logData from '@/libs/log'
import { missingClassText } from '@/libs/dnd'

export default {
  name: 'SignatureSetup',
  components: {
    ErrorDisplay,
    LenonMultiSelect,
    LenonInput,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      removedClassIds: [],
      missingClassText,
      allClasses: false,
      signatureModalOpened: false,
      updateSignatureMode: false,
      signatureLoading: false,
      selectedSignatureId: null,
      documentTypes: [
        {
          value: 1,
          label: 'Terminal Report',
        },
        {
          value: 2,
          label: 'Receipt',
        },
      ],
      signature: {
        id: null,
        title: null,
        name: null,
        document_type: null,
        signature: null,
        classes: [],
      },
    }
  },
  computed: {
    signatures() {
      return this.$store.getters['schoolSignatures/signatures']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    filteredClasses() {
      let selectedClasses = []
      // compile already selected classes
      this.signatures.forEach(gs => {
        selectedClasses = [...selectedClasses, ...gs.classes.map(c => c)]
      })
      // return classes not selected yet
      return this.classes.filter(cls => {
        if (this.removedClassIds.includes(cls.id)) {
          return true
        }
        return !selectedClasses.includes(cls.id)
      })
    },
    selectedSignature() {
      return this.signatures.find(gs => +gs.id === +this.selectedSignatureId)
    },
  },
  watch: {
    allClasses(checked) {
      if (checked) {
        this.signature.classes = this.filteredClasses
      } else {
        this.signature.classes = []
      }
    },
    modalOpened(opened) {
      this.signatureModalOpened = opened
    },
  },
  methods: {
    handleRemoveClass(id) {
      this.removedClassIds.push(id)
    },
    onBegin() {
      this.$refs.signaturePad.resizeCanvas()
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature()
      this.signature.signature = null
    },
    onEnd() {
      const {
        isEmpty,
        data,
      } = this.$refs.signaturePad.saveSignature()
      if (!isEmpty) {
        this.signature.signature = data
      }
    },
    closeSignatureModal() {
      // this.removedClassIds = []
      this.signatureModalOpened = false
      this.$emit('modalClosed')
    },
    resetSignatureForm() {
      this.signature = {
        id: null,
        name: null,
        signature: null,
        classes: [],
      }
      this.selectedSignatureId = null
      this.updateSignatureMode = false
      this.$refs.signaturePad.clearSignature()
      this.$refs.signatureForm.reset()
    },
    populateSignatureForm(sf) {
      if (this.selectedSignature) {
        this.updateSignatureMode = true
          this.removedClassIds = []
          this.signature = {
            ...this
              .selectedSignature,
            classes: this.selectedSignature.classes.map(c => this.classes.find(cl => c === cl.id)),
          }
          this.$refs.signaturePad.saveSignature()
          // eslint-disable-next-line no-underscore-dangle
          delete this.signature.__typename
          delete this.signature.signed_by
          delete this.signature.updated_at
          this.$refs.signaturePad.resizeCanvas()
          this.$refs.signaturePad.fromDataURL(this.signature.signature)
        } else {
          this.resetSignatureForm()
        }
    },
    createSignature() {
      this.error = {}
      this.signatureLoading = true
      const signature = {
        ...this.signature,
        classes: this.signature.classes.map(c => c.id),
      }
      delete signature.name
      this.$apollo.mutate({
        mutation: CREATE_SIGNATURE_M,
        variables: { input: signature },
      })
        .then(res => {
          this.signatureLoading = false
          this.$store.commit('schoolSignatures/addSignature', res.data.createSignature)
          this.showSuccess('Created Successfully')
          this.resetSignatureForm()
        })
        .catch(err => {
          this.signatureLoading = false
          logData(err)
          this.error = err
          this.showError('Something went wrong, please try again')
        })
    },
    deleteSignature() {
      if (!this.selectedSignatureId) {
        this.showInfo('Please select a signature')
        return
      }
      this.$apollo.mutate({
        mutation: DELETE_SIGNATURE_M,
        variables: { id: this.selectedSignatureId },
      })
        .then(() => {
          this.showSuccess('Deleted Successfully')
          this.$store.commit('schoolSignatures/removeSignature', this.selectedSignatureId)
          this.selectedSignatureId = null
          this.resetSignatureForm()
        })
        .catch(() => {
          this.deletingSignature = false
        })
    },
    updateSignature() {
      this.error = {}
      if (!this.signature.id) {
        return
      }
      const signature = {
        ...this.signature,
        classes: this.signature.classes.map(c => c.id),
      }
      delete signature.name
      this.signatureLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_SIGNATURE_M,
        variables: { input: signature },
      })
        .then(res => {
          this.showSuccess('Updated signature')
          this.signatureLoading = false
          this.$store.commit('schoolSignatures/updateSignature', res.data.updateSignature)
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update signature')
          this.signatureLoading = false
        })
    },
  },
}
</script>
